// global variables
(function ($, Vuex, window, document, Debugger) {
  /** @type {Object} Global App object */
  const App = {
    is: {
      iOS: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
      iPad: navigator.platform === 'iPad' || navigator.platform === 'Linux armv7l',
      get mobile() {
        return window.innerWidth < 768;
      },
      get tablet() {
        return window.innerWidth >= 768 && window.innerWidth <= 1024;
      },
      get touch() {
        return $('html').hasClass('touch');
      },
    },
    hasDebug() {
      return (
        window.prestashop.modules.meta_frontsettings &&
        'HAS_DEBUG' in window.prestashop.modules.meta_frontsettings.CONFIG &&
        window.prestashop.modules.meta_frontsettings.CONFIG.HAS_DEBUG
      );
    },
    components: {},
    store: new Vuex.Store({
      modules: {
        // Pages specific stores must be registered as `page` submodules:
        // App.store.registerModule(['page', '<pageName>'], config);
        page: {
          namespaced: true,
        },
        // Modules specific stores must be registered as 'module' submodules
        // App.store.registerModule(['module', '<moduleName>'], config);
        module: {
          namespaced: true,
        },
      },
    }),
    bus: new Vue(),
    helpers: {
      /**
       * Refresh uniform fields.
       *
       * @return {void}
       */
      refreshUniform: function refreshUniform() {
        // Uniform radio, checkbox.
        $(
          "input[type='checkbox']:not(.comparator):not('.CybotCookiebotDialogBodyLevelButton'):not('.CybotCookiebotDialogBodyLevelConsentCheckbox'):not(.form__checkbox):not([id*='facet_']), #page-wrapper input[type='radio']:not(.form__radio):not([id*='facet_']),input#id_carrier2"
        ).uniform();

        if (!App.is.mobile) {
          // Uniform select, file only on mobile.
          $(
            "#page-wrapper select.form-control, #page-wrapper :not(.uploader) input[type='file']"
          ).uniform();
        }

        $(window).resize(function () {
          if (!App.is.mobile) {
            // refresh uniform selects on document width change
            $.uniform.update(
              "#page-wrapper select.form-control, #page-wrapper :not(.uploader) input[type='file']"
            );
          }
        });
      },
      /**
       * Disable page scroll
       */
      disableScroll: function disableScroll() {
        // Replace the jQuery scrollTop function
        // to avoid unwanted `$(document).scrollTop`
        // when the modal is open
        this.saveScrollTopFn = $.fn.scrollTop;
        $.fn.scrollTop = function () {};
        $('html').css({ overflow: 'hidden' });
      },

      /**
       * Enable page scroll
       */
      enableScroll: function enableScroll() {
        $.fn.scrollTop = this.saveScrollTopFn;
        $('html').css({ overflow: '' });
      },

      /**
       * Convert a classic object into a map
       *
       * @param  {Object} object     The object to convert
       * @param  {Map}    initialMap The initial map value
       * @return {Map}               The created map instance
       */
      objectToMap: function objectToMap(object, initialMap) {
        const baseMap = initialMap || new Map();
        // Return an empty map or initialMap if object is undefined or falsy
        if (!object) {
          return baseMap;
        }

        return Object.keys(object).reduce(function reduceObject(map, key) {
          return map.set(key, object[key]);
        }, baseMap);
      },

      /**
       * Format a CSS transform matrix with the given values
       *
       * @param  {Number} options.scaleX     The scale on the x axis
       * @param  {Number} options.scaleY     The scale on the y axis
       * @param  {Number} options.skewX      The skew on the x axis
       * @param  {Number} options.skewY      The skew on the y axis
       * @param  {Number} options.translateX The translate on the x axis
       * @param  {Number} options.translateY The translate on the y axis
       * @return {String}                    A formatted CSS matrix transform
       */
      formatMatrix: function formatMatrix(transforms) {
        const scaleX = transforms.scaleX || 1;
        const scaleY = transforms.scaleY || 1;
        const skewX = transforms.skewX || 0;
        const skewY = transforms.skewY || 0;
        const translateX = transforms.translateX || 0;
        const translateY = transforms.translateY || 0;

        const matrix = [scaleX, skewX, skewY, scaleY, translateX, translateY];

        return `matrix(${matrix.join(', ')})`;
      },

      /**
       * Format a given price following PS settings
       * @param  {Number} price The price to format
       * @return {String}       The formatted price
       */
      formatPrice: function formatPrice(price, callback) {
        return window.formatCurrencyCldr(price, callback);
      },

      /**
       * Scroll to a given position according to the header height and execute
       * the callback
       *
       * @param  {Number} scroll The scroll target
       * @return {void}
       */
      scrollTo: function scrollTo(scroll, callback) {
        TweenLite.to(window, 0.8, {
          scrollTo: {
            y: scroll - this.headerHeight,
            autoKill: !App.is.iPad,
          },
          ease: Expo.easeInOut,
          onComplete() {
            if (typeof callback === 'function') {
              callback();
            }
          },
        });
      },

      /**
       * Get the header height
       *
       * @return {Number} The header outerheight
       */
      get headerHeight() {
        if (!this.$header) {
          this.$header = $('#js-header');
        }
        return this.$header.outerHeight();
      },

      /**
       * Try and get the user current country ID base on the navigator
       *
       * @param {Array} Available countries.
       * @return {String} The ID of the PS country
       */
      guessUserCountryId: function guessUserCountryId(countries) {
        const languagesExists =
          window.navigator &&
          window.navigator.languages &&
          Array.isArray(window.navigator.languages);

        if (!countries || !languagesExists) {
          return '';
        }

        const countryKeys = Object.keys(countries);
        const languages = window.navigator.languages.map(function (lang) {
          return lang.toUpperCase();
        });

        return languages.reduce(function (acc, language) {
          return countryKeys.reduce(function (acc1, id, index, arr) {
            // Break early
            if (acc1) {
              arr.splice(1);
              return acc1;
            }
            const isoCode = countries[id].iso_code.toUpperCase();
            return (language === isoCode || language.split('-').includes(isoCode)) && !acc1
              ? id
              : acc1;
          }, acc);
        }, '');
      },

      /**
       * Render the HTML entities in the given string
       *
       * @param  {String} string The string to render
       * @return {String}        The rendered string
       */
      decodeHtmlEntities: (function iife() {
        const txt = document.createElement('textarea');
        return function decodeHtmlEntities(string) {
          txt.innerHTML = string;
          return txt.value;
        };
      })(),

      /**
       * Generate a simple uniq ID
       * @return {String}
       */
      uniqId: function uniqId(prefix) {
        const uniq = new Date().getTime() + Math.floor(Math.random() * 10000 + 1);
        return prefix ? `${prefix}-${uniq.toString(16)}` : uniq.toString(16);
      },
    },
    i18n: {
      /**
       * Add a translation object to the global translations
       *
       * @param {Object} translations The object of translations
       * @param {String} domain       The domain of the translations
       */
      add: function add(translations, domain) {
        /* eslint-disable-next-line */
				domain = domain || '*';

        const map = App.helpers.objectToMap(translations, this.data.get(domain));
        this.data.set(domain, map);
      },
      /**
       * Check if the given string exists in the given domain
       *
       * @param  {String}  string The string to check
       * @param  {String}  domain The domain to check
       * @return {Boolean}        Whether the translation exists in the domain
       */
      has: function has(string, domain) {
        if (!this.data.has(domain)) {
          return false;
        }

        const data = this.data.get(domain);
        return data.has(string);
      },
      /**
       * Get a translated string by its key
       *
       * @param  {String} string  The key of the string to translate
       * @param  {String} domain  The domain of the translation
       * @param  {Object} replace An object for element to replace
       * @return {String}         The translated string
       */
      l: function l(string, domain, replace) {
        /* eslint-disable-next-line */
				domain = domain || 'global';
        const data = this.data.get(domain);
        let translation = data && data.has(string) ? data.get(string) : string;

        if (replace) {
          Object.keys(replace).forEach(function (key) {
            const search = new RegExp(`\\[\\[\\s?${key}\\s?\\]\\]`, 'g');
            translation = translation.replace(search, replace[key]);
          });
        }

        return App.helpers.decodeHtmlEntities(translation);
      },
      /** @type {Map} Translations map */
      data: new Map(),
      id:
        window.prestashop.modules.meta_frontsettings &&
        'ID_LANG' in window.prestashop.modules.meta_frontsettings.CONFIG
          ? window.prestashop.modules.meta_frontsettings.CONFIG.ID_LANG
          : 0,
    },
  };

  // Register global translations
  App.i18n.add(window.__GLOBAL_I18N__, 'global');

  // Legacy `is` check
  App.isMobile = function () {
    return App.is.mobile;
  };
  App.isIpad = function () {
    return App.is.iPad;
  };

  // Register utilities components
  App.components.Debugger = Debugger;
  App.components.md = new window.MobileDetect(window.navigator.userAgent);

  /* ===========================================================================
   * Define a `breakpoint` property and a method to test a breakpoint validity
   * ======================================================================== */

  Object.defineProperty(App, 'breakpoint', {
    get() {
      return window
        .getComputedStyle(document.getElementById('columns-container'), '::before')
        .getPropertyValue('content')
        .replace(/"/g, '');
    },
  });

  /**
   * Test if the current breakpoint is in the given list
   * @param  {Array}   breakpoints The list of breakpoints to test
   * @return {Boolean}             Whether or not a breakpoint in the list is active
   */
  App.is.inBreakpoints = function (breakpoints) {
    return $.inArray(App.breakpoint, breakpoints) > -1;
  };

  /**
   * Test if the given element is visible in the viewport
   * @param  {HTMLElement} element The element to test
   * @param  {Number}      offset  An offset for the detection
   * @return {Boolean}             Is the element in the viewport?
   */
  App.is.inViewport = function (element, offset) {
    /* eslint-disable-next-line */
		offset = typeof offset === 'number' ? offset : 0;
    const boundingClientRect = element.getBoundingClientRect();
    const viewHeight = window.innerHeight;
    return (
      (boundingClientRect.top >= offset && boundingClientRect.top <= viewHeight - offset) ||
      (boundingClientRect.bottom <= viewHeight - offset && boundingClientRect.bottom >= offset)
    );
  };

  /**
   * Create a jQuery alias for the App.helpers.scrollTo function
   *
   * @param  {String|jQuery|HTMLElement} target The target of the scroll
   * @return {void}
   */
  $.scrollTo = function (target) {
    if (!target) {
      return;
    }

    const offset = $(target).offset().top;
    App.helpers.scrollTo(offset);
  };

  window.App = App;
})(jQuery, Vuex, window, document, Debugger);

/**
 * Animate scroll for anchor links
 * data-offset can be use to specify a custom offset.
 * @return {void}
 */
function smoothScroll() {
  const isSamePath =
    window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '');
  const isSameHost = window.location.hostname === this.hostname;

  if (isSamePath && isSameHost) {
    const $this = $(this);
    let $target = $(this.hash);
    const offset =
      $this.data('offset') !== 'undefined'
        ? $target.offset().top - $($this.data('offset')).height()
        : $target.offset().top;

    // Take care of PS behaviour with anchors.
    $target = $target.length ? $target : $(`[name="${this.hash.replace('#', '')}"]`);

    if (
      App.is.touch &&
      (App.is.mobile || App.is.tablet) &&
      $this.data('double-tap') !== undefined &&
      !$this.data('has-tapped')
    ) {
      $this.data('has-tapped', true);
      return false;
    }

    $this.data('has-tapped', false);

    if ($target.length) {
      App.helpers.scrollTo(offset);
      return false;
    }
  }

  return true;
}

/**
 * Helper function to manage/display Flickity carousel.
 */
function loadSlickCarousel(options, selector) {
  let carouselSelector = '.slick-carousel';

  if (typeof selector !== 'undefined') {
    carouselSelector = selector;
  }

  const defaultOptions = { infinite: true };

  const carouselOptions =
    typeof options === 'object' ? $.extend({}, defaultOptions, options) : defaultOptions;

  $(carouselSelector).slick(carouselOptions);
}

$(document).ready(function () {
  $(document).on('click', '.back', function (e) {
    e.preventDefault();
    window.history.back();
  });

  // Add scrollTo mechanism on each .scrollTo element.
  $('a.scrollTo, .js-scroll-to').on('click', smoothScroll);

  /** Google Analytics function alias */
  const ga = window.ga || function () {};

  /* ================================
   * SEO
   * ================================ */
  $('.seo-ga-click').on('click', function () {
    const $this = $(this);
    const eventCategory = $this.data('event-category');
    const eventAction = $this.data('event-action');
    const eventLabel = $this.data('event-label');
    ga('send', 'event', eventCategory, eventAction, eventLabel);
  });

  /**
   * Newsletters
   */
  $('#blocknewsletter-popin').on('submit', function () {
    ga('send', 'event', 'Newsletter', 'Inscription', 'Inscription_Newsletter_pop_in');
  });

  $('#blocknewsletter-footer').on('submit', function () {
    ga('send', 'event', 'Newsletter', 'Inscription', 'Inscription_Newsletter_Footer');
  });

  /**
   * Blocks push Homepage
   */
  $('.seo-ga-hp-click').on('click', function () {
    const classes = $(this).attr('class').split(' ');
    let label = null;
    classes.forEach(function (element) {
      if (element.indexOf('push-') === 0) {
        // eslint-disable-next-line prefer-destructuring
        label = element.split('-')[1];
      }
    });
    label = label.charAt(0).toUpperCase() + label.slice(1);

    ga('send', 'event', 'Clic_Push', 'Clic_Push_Homepage', `Clic_Push_Homepage_${label}`);
  });

  jQuery.curCSS = jQuery.css;
  if ($.prototype.cluetip)
    $('a.cluetip')
      .cluetip({
        local: true,
        cursor: 'pointer',
        dropShadow: false,
        dropShadowSteps: 0,
        showTitle: false,
        tracking: true,
        sticky: false,
        mouseOutClose: true,
        fx: {
          open: 'fadeIn',
          openSpeed: 'fast',
        },
      })
      .css('opacity', 0.8);

  // Close Alert messages
  $('.alert.alert-danger').on('click', this, function () {
    $(this).hide();
  });
});

$(window).on('load', function () {
  if ('Vue' in window) {
    Vue.config.devtools = App.hasDebug();
    Vue.config.productionTip = App.hasDebug();
  }

  if ($('#product').length > 0) {
    const options = {
      speed: 500,
      fade: true,
      cssEase: 'linear',
    };
    loadSlickCarousel(options);
  }

  if ($('.mobile-filter-btn').length > 0) {
    $('.mobile-filter-btn').on('click', function () {
      $('body').addClass('open-filter');
    });

    $('.facet-filter__close').on('click', function () {
      $('body').removeClass('open-filter');
    });
  }

  App.helpers.refreshUniform();

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  // Datalayer
  if ($('.facet-dropdown').length > 0) {
    $('.facet-dropdown').on('change', function (e) {
      e.preventDefault();

      const datalayer = {
        event: 'click_item_list_filter',
      };

      $('.facet-dropdown').each(function (index, element) {
        const optionSelected = $(element).find('option:selected');
        const facetType = $(element).data('type');

        if (optionSelected.val() != '') {
          const value = trim_text(optionSelected.text());
          if (facetType == 'tailles homme' || facetType == 'men&#039;s size') {
            datalayer.size = value;
          }

          if (facetType == 'tailles françaises h' || facetType == 'm french sizes') {
            datalayer.size_europe = parseInt(value, 10);
          }

          if (facetType == "tailles ceinture" || facetType == "waist size") {
            datalayer.waist_size = value;
          }
          if (facetType == "couleur" || facetType == "color") {
            datalayer.color = value;
          }
          if (facetType == "matière" || facetType == "leather") {
            datalayer.material = value;
          }

          if (facetType == 'forme' || facetType == 'shape') {
            datalayer.shape = value;
          }

          if (facetType == 'semelle' || facetType == 'sole') {
            datalayer.sole = value;
          }
        }
      });

      window.dataLayer.push(datalayer);
    });
  }

  $('.js-datalayer').on('click', function() {
    let datalayer = {};

    if($(this).data('values')) {
      datalayer = $(this).data('values');
    } else {
      datalayer = {
        event: $(this).data('event'),
        element_label: $(this).data('label') ? trim_text($(this).data('label')) : trim_text($(this).text()),
      }
    }

    if ($(this).data('timestamp')) {
      datalayer.timestamp = get_timestamp();
    }

    if (datalayer.item_id && !Number.isInteger(datalayer.item_id)) {
      datalayer.item_id = parseInt(datalayer.item_id);
    }

    window.dataLayer.push(datalayer);
  });

  $('[name="submitNewsletter"]').on('click', function() {
    window.dataLayer.push(
    {
      event: 'newsletter_registration',
      timestamp: get_timestamp(),
      link_url: window.location.href,
      element_id: $(this).parents('form').attr('id'),
    });
  });

  /**
   * Get timestamp for datalayer
   * @return {String}
   */
  function get_timestamp() {
    var d = new Date();

    var month = d.getMonth()+1;
    var day = d.getDate();

    return d.getFullYear() + '-' +
        (month<10 ? '0' : '') + month + '-' +
        (day<10 ? '0' : '') + day;
  }

  /**
   * Sanitize text for datalayer
   * @param  {String} value text to trim.
   * @return {String}       beautiful text.
   */
  function trim_text(value) {
    return $.trim(value.replace(/[\t\n]+/g, ' '));
  }
});
